import React from 'react';
import { ErrorPage, ProtectedRoute } from '@recargas-dominicanas/core/components';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Login } from '../login/login.component';
import { Main } from '../main/main.component';
export function App() {
    return (React.createElement(BrowserRouter, null,
        React.createElement(Switch, null,
            React.createElement(Route, { exact: true, path: '/login' },
                React.createElement(Login, null)),
            React.createElement(Route, { exact: true, path: '/error' },
                React.createElement(ErrorPage, { image: 'server-error.png' })),
            React.createElement(ProtectedRoute, { path: '/' },
                React.createElement(Main, null)))));
}
