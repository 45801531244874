var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { Error, OutlineButton, TextField } from '@recargas-dominicanas/core/components';
import { useForm, validators } from '@recargas-dominicanas/core/utils';
import { useHistory } from 'react-router-dom';
import { AppToolbar } from '@recargas-dominicanas/core/components';
import { style } from './add-business.module.css';
import { CustomerApi } from '@recargas-dominicanas/core/api';
export function AddBusiness() {
    var history = useHistory();
    var _a = useState(false), showError = _a[0], setShowError = _a[1];
    var customer = useForm({
        name: '',
        docNumber: '',
        phone: '',
        email: ''
    });
    var business = useForm({
        name: '',
        rnc: '',
        phone: '',
        email: '',
        city: '',
        address: ''
    });
    function handleClose() {
        customer.clear();
        business.clear();
        history.goBack();
        setShowError(false);
    }
    function handleSave() {
        return __awaiter(this, void 0, void 0, function () {
            var customerIsValid, businessIsValid, result, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        customerIsValid = customer.isValid();
                        businessIsValid = business.isValid();
                        if (!customerIsValid || !businessIsValid)
                            return [2 /*return*/];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, CustomerApi.createCustomer(customer.value, business.value)];
                    case 2:
                        result = _a.sent();
                        history.replace('/added-business', result);
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        setShowError(true);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    }
    return (React.createElement("div", { className: style.container },
        React.createElement(AppToolbar, { icon: 'arrow_back', title: 'Agregar cliente', onClick: handleClose }),
        React.createElement("div", { className: style.content },
            React.createElement("div", { className: style.title }, "Datos del cliente"),
            React.createElement(TextField, { style: style.textField, placeholder: 'Nombre', formField: customer.fields.name, validators: [validators.required] }),
            React.createElement(TextField, { style: style.textField, placeholder: 'C\u00E9dula', formField: customer.fields.docNumber, validators: [
                    validators.required,
                    validators.length(11, 'Cédula invalida')
                ] }),
            React.createElement(TextField, { style: style.textField, placeholder: 'Tel\u00E9fono', formField: customer.fields.phone, validators: [
                    validators.required,
                    validators.phone
                ] }),
            React.createElement(TextField, { style: style.textField, placeholder: 'Correo electr\u00F3nico', formField: customer.fields.email, validators: [validators.email] }),
            React.createElement("div", { className: style.title }, "Datos del negocio"),
            React.createElement(TextField, { style: style.textField, placeholder: 'Nombre', formField: business.fields.name, validators: [validators.required] }),
            React.createElement(TextField, { style: style.textField, placeholder: 'RNC', formField: business.fields.rnc }),
            React.createElement(TextField, { style: style.textField, placeholder: 'Tel\u00E9fono', formField: business.fields.phone, validators: [
                    validators.required,
                    validators.phone
                ] }),
            React.createElement(TextField, { style: style.textField, placeholder: 'Correo electr\u00F3nico', formField: business.fields.email, validators: [validators.email] }),
            React.createElement(TextField, { style: style.textField, placeholder: 'Ciudad', formField: business.fields.city, validators: [validators.required] }),
            React.createElement(TextField, { style: style.textField, placeholder: 'Direcci\u00F3n', formField: business.fields.address, validators: [validators.required] }),
            React.createElement(Error, { style: style.error, show: showError, message: 'Ocurrio un error al guardar el cliente' }),
            React.createElement(OutlineButton, { style: style.button, text: 'Guardar', onClick: handleSave }))));
}
