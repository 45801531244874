// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".add-business-container-kEz23r {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n}\n\n.add-business-content-KuiZDo {\n  flex-grow: 1;\n  overflow: auto;\n  padding: 0px 14px 0px 24px;\n}\n\n.add-business-title-KApAPj {\n  margin: 32px 0px;\n  font-size: 16px;\n  font-weight: 500;\n}\n\n.add-business-textField_container-mrAKMe {\n  width: 100%;\n  margin: 24px 0px;\n}\n\n.add-business-error_container-a6c5Zj {\n  margin-top: 28px;\n}\n\n.add-business-button_base-WEAU6v {\n  width: 136px;\n  height: 36px;\n  margin: 40px 0px 28px auto;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/add-business/add-business.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,0BAA0B;AAC5B;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,0BAA0B;AAC5B","sourcesContent":[".container {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n}\n\n.content {\n  flex-grow: 1;\n  overflow: auto;\n  padding: 0px 14px 0px 24px;\n}\n\n.title {\n  margin: 32px 0px;\n  font-size: 16px;\n  font-weight: 500;\n}\n\n.textField_container {\n  width: 100%;\n  margin: 24px 0px;\n}\n\n.error_container {\n  margin-top: 28px;\n}\n\n.button_base {\n  width: 136px;\n  height: 36px;\n  margin: 40px 0px 28px auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "add-business-container-kEz23r",
	"content": "add-business-content-KuiZDo",
	"title": "add-business-title-KApAPj",
	"textField_container": "add-business-textField_container-mrAKMe",
	"error_container": "add-business-error_container-a6c5Zj",
	"button_base": "add-business-button_base-WEAU6v"
};
export default ___CSS_LOADER_EXPORT___;
