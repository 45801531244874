// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".info-item-container-M9x2BY {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-top: 28px;\n}\n\n.info-item-title-x9dEsF {\n  font-size: 15px;\n}\n\n.info-item-value-Cp6Rsk {\n  font-size: 15px;\n  font-weight: 500;\n  user-select: text;\n}\n\n.info-item-black-PXLafM {\n  color: rgba(0, 0, 0, 80%);\n}\n\n.info-item-green-im3pSb {\n  color: var(--money-color);\n}\n\n.info-item-red-FolxQ1 {\n  color: var(--accent-color);\n}\n", "",{"version":3,"sources":["webpack://./src/components/info-item/info-item.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".container {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-top: 28px;\n}\n\n.title {\n  font-size: 15px;\n}\n\n.value {\n  font-size: 15px;\n  font-weight: 500;\n  user-select: text;\n}\n\n.black {\n  color: rgba(0, 0, 0, 80%);\n}\n\n.green {\n  color: var(--money-color);\n}\n\n.red {\n  color: var(--accent-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "info-item-container-M9x2BY",
	"title": "info-item-title-x9dEsF",
	"value": "info-item-value-Cp6Rsk",
	"black": "info-item-black-PXLafM",
	"green": "info-item-green-im3pSb",
	"red": "info-item-red-FolxQ1"
};
export default ___CSS_LOADER_EXPORT___;
