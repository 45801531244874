// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header-container-OzqPdz {\n  height: 56px;\n  flex-shrink: 0;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0px 16px;\n  box-shadow: 0px 3px 6px #0000000D;\n}\n\n.header-logoContainer-uvJaPL {\n  display: flex;\n  align-items: center;\n}\n\n.header-logo-wrGgW7 {\n  height: 24px;\n  width: 25px;\n}\n\n.header-title-on7Ozb {\n  margin-left: 16px;\n  font-size: 16px;\n  font-weight: 500;\n}\n", "",{"version":3,"sources":["webpack://./src/components/header/header.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,cAAc;EACd,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,iBAAiB;EACjB,iCAAiC;AACnC;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".container {\n  height: 56px;\n  flex-shrink: 0;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0px 16px;\n  box-shadow: 0px 3px 6px #0000000D;\n}\n\n.logoContainer {\n  display: flex;\n  align-items: center;\n}\n\n.logo {\n  height: 24px;\n  width: 25px;\n}\n\n.title {\n  margin-left: 16px;\n  font-size: 16px;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "header-container-OzqPdz",
	"logoContainer": "header-logoContainer-uvJaPL",
	"logo": "header-logo-wrGgW7",
	"title": "header-title-on7Ozb"
};
export default ___CSS_LOADER_EXPORT___;
