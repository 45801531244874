// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".drawer-drawerHeader-TNpuCP {\n  border-bottom: 1px solid var(--border-color);\n  padding: 16px;\n  padding-bottom: 18px;\n}\n\n.drawer-drawerImage-Uvp7QJ {\n  width: 32px;\n  height: 32px;\n  display: block;\n}\n\n.drawer-drawerTitle-xrY45I {\n  font-size: 16px;\n  font-weight: 500;\n  margin-top: 16px;\n}\n\n.drawer-drawerName-Q5HPbk {\n  font-size: 15px;\n  margin-top: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/drawer/drawer.module.css"],"names":[],"mappings":"AAAA;EACE,4CAA4C;EAC5C,aAAa;EACb,oBAAoB;AACtB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".drawerHeader {\n  border-bottom: 1px solid var(--border-color);\n  padding: 16px;\n  padding-bottom: 18px;\n}\n\n.drawerImage {\n  width: 32px;\n  height: 32px;\n  display: block;\n}\n\n.drawerTitle {\n  font-size: 16px;\n  font-weight: 500;\n  margin-top: 16px;\n}\n\n.drawerName {\n  font-size: 15px;\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"drawerHeader": "drawer-drawerHeader-TNpuCP",
	"drawerImage": "drawer-drawerImage-Uvp7QJ",
	"drawerTitle": "drawer-drawerTitle-xrY45I",
	"drawerName": "drawer-drawerName-Q5HPbk"
};
export default ___CSS_LOADER_EXPORT___;
