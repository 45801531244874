// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".business-container-Jkmj2H {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n}\n\n.business-content-ih5Lei {\n  flex-grow: 1;\n  overflow: auto;\n}\n\n.business-navItems-KQeSnu {\n  padding: 16px 0px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/business/business.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".container {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n}\n\n.content {\n  flex-grow: 1;\n  overflow: auto;\n}\n\n.navItems {\n  padding: 16px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "business-container-Jkmj2H",
	"content": "business-content-ih5Lei",
	"navItems": "business-navItems-KQeSnu"
};
export default ___CSS_LOADER_EXPORT___;
