// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".added-business-title-ZYAZtM {\n  color: rgba(0, 0, 0, 90%);\n  font-size: 16px;\n  font-weight: 500;\n  margin: 28px 16px 0px;\n}\n\n.added-business-link-HlQjcL {\n  color: #1978E5;\n  word-break: break-all;\n  user-select: text;\n  margin: 28px 16px;\n}\n\n.added-business-copy-HuPyXd {\n  height: 40px;\n  line-height: 40px;\n  color: rgba(0, 0, 0, 85%);\n  background-color: rgba(0, 0, 0, 5%);\n  font-weight: 500;\n  font-size: 15px;\n  border-radius: 4px;\n  text-align: center;\n  margin: 40px 16px;\n}\n\n.added-business-copy-HuPyXd:active {\n  background-color: rgba(0, 0, 0, 10%);\n}\n", "",{"version":3,"sources":["webpack://./src/pages/added-business/added-business.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,eAAe;EACf,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,yBAAyB;EACzB,mCAAmC;EACnC,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":[".title {\n  color: rgba(0, 0, 0, 90%);\n  font-size: 16px;\n  font-weight: 500;\n  margin: 28px 16px 0px;\n}\n\n.link {\n  color: #1978E5;\n  word-break: break-all;\n  user-select: text;\n  margin: 28px 16px;\n}\n\n.copy {\n  height: 40px;\n  line-height: 40px;\n  color: rgba(0, 0, 0, 85%);\n  background-color: rgba(0, 0, 0, 5%);\n  font-weight: 500;\n  font-size: 15px;\n  border-radius: 4px;\n  text-align: center;\n  margin: 40px 16px;\n}\n\n.copy:active {\n  background-color: rgba(0, 0, 0, 10%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "added-business-title-ZYAZtM",
	"link": "added-business-link-HlQjcL",
	"copy": "added-business-copy-HuPyXd"
};
export default ___CSS_LOADER_EXPORT___;
